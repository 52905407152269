import React, { useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "../index.css";
import { scheduleModel } from "../data/scheduleModel";
import { themeJson } from "./theme";
import PublishEvent from "../api/PublishEvent";
import { formatDateForSystem } from './DateUtils';
import { formatDateForDisplay } from './DateUtils';
import ReactGA from 'react-ga4';
import { Backdrop, CircularProgress } from '@mui/material';

function SurveyComponent({ selectedEvent, emergency }) {
    const [finalMessage, setFinalMessage] = useState(null)

    const [loadingShow, setLoadingShow] = useState(false)


    const survey = new Model(scheduleModel);
    const isEmergency = emergency
    // You can delete the line below if you do not use a customized theme
    survey.applyTheme(themeJson);
    
    survey.onStarted.add(() => {
        setFinalMessage(null)
        ReactGA.event({
            category: 'Questionnaire',
            action: 'Début questionnaire',
            label: 'Questionnaire',
          });
    });
    survey.onCurrentPageChanged.add((_, options) => {
        ReactGA.event({
            category: 'Questionnaire',
            action: 'Changement page',
            value: options.newCurrentPage.visibleIndex,
          });
    });
    survey.onComplete.add((sender, options) => {
        ReactGA.event({
            category: 'Questionnaire',
            action: 'Envoie de la demande'
          });
          
        let surveyData = sender.data
        surveyData["emergency"] = emergency
        var fileName
        if (emergency) {
            fileName = encodeURIComponent( "Urgence---" + Date.now())
        } else {
            const dateSystemStr = formatDateForSystem(new Date(selectedEvent.startDate))
            const dateStr = formatDateForDisplay(new Date(selectedEvent.startDate))
            surveyData["selectedDate"] = dateStr
            surveyData["selectedSystemDate"] = dateSystemStr
            surveyData["eventId"] = selectedEvent.id

            fileName = encodeURIComponent(dateSystemStr + "---" + Date.now())
        }

        
        //console.log(JSON.stringify(surveyData, null, 3));
        if (surveyData.inQc === true) {
            setLoadingShow(true)
            new PublishEvent(fileName, surveyData)
            .then((result) => {
                if (result.status === 200) {
                    setFinalMessage(<>Votre demande a été avec succès</>)
                } else {
                    setFinalMessage(<>Une erreur est survenue avec votre demande. Veuillez réessayer plus tard ou nous écrire par courriel à <a href="mailto:info@cindyvet.com">info@cindyvet.com</a>.</>)
                }
                setLoadingShow(false)
                return {};
            }).then(() => {
                setLoadingShow(false)
            });
        }
    });
    return (<>
        <div class="sd-root-modern"  >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingShow}
                >
                <CircularProgress aria-label='Loading' color="inherit" />
            </Backdrop>
             {finalMessage === null &&  <Survey model={survey} /> }
            <div class='sv-components-row'>
                <div class="sd-body sd-body--static" >                
                    <br/>
                    <label class="label-medium" >
                        {finalMessage}
                    </label>
                </div>
            </div>
        </div>
    </>);
}

export default SurveyComponent;